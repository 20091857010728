.feedbackFormContainer {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .title {
    color: #333;
    margin-bottom: 20px;
  }
  
  .emojiContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .emojiItem {
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .emojiItem.selected {
    transform: scale(1.2);
  }
  
  .emojiLabel {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #333;
  }
  
  .feedbackTextarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #279D8E;
    border-radius: 8px;
    resize: none;
    margin-bottom: 20px;
  }
  
  .submitButton {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #279D8E;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .submitButton:hover {
    background-color: #227f74;
  }
  